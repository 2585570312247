import React, { useState } from "react";
import "./Header.css";

import logo from "../assets/images/rsc_logo_v1.png";

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLinkClick = () => {
    setIsNavOpen(false);
  };

  return (
    <header className="header">
      <div className="header-top">
        <div className="logo-container">
          <img src={logo} alt="Romsey Shooting Club Logo" className="logo" />
          <span className="club-name">Romsey Shooting Club</span>
        </div>
        <button
          className={`hamburger ${isNavOpen ? "active" : ""}`}
          onClick={toggleNav}
          aria-label="Toggle navigation menu"
        >
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>
        <nav className={`nav ${isNavOpen ? "nav-open" : ""}`}>
          <a href="/#about-us" className="nav-link" onClick={handleLinkClick}>
            About Us
          </a>
          <a
            href="/#how-to-join"
            className="nav-link"
            onClick={handleLinkClick}
          >
            How to Join
          </a>
          <a
            href="/#disciplines"
            className="nav-link"
            onClick={handleLinkClick}
          >
            Disciplines
          </a>
          <a
            href="/secured/members.html"
            className="nav-link"
            onClick={handleLinkClick}
          >
            Members
          </a>
          <a
            href="/#contact-us"
            className="contact-button"
            onClick={handleLinkClick}
          >
            Contact Us
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
